/* ContactUsBanner.css */
.contact-us-banner {
    background-image: url(../../../../assets/Banner/contactbg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px; /* Adjust as per your need */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #FF8400;
    text-align: center;
  }
  
  .contact-us-banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5);  */
  }
  
  .banner-content {
    position: relative;
    z-index: 1; /* Ensure content is above the background image */
  }
  
  .banner-content h1 {
    font-size: 48px;
    font-weight: bold;
    text-transform: uppercase;
    color: #FF8400;
    font-family: El Messiri;
  }
  