/* Center the loading message */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
}

/* Animation for the dots */
@keyframes blink {
    0%, 20% {
        opacity: 1;
    }
    20%, 40% {
        opacity: 0;
    }
    40%, 60% {
        opacity: 1;
    }
    60%, 80% {
        opacity: 0;
    }
    80%, 100% {
        opacity: 1;
    }
}

.dot-1 {
    animation: blink 1.4s infinite;
}
.dot-2 {
    animation: blink 1.4s infinite 0.2s;
}
.dot-3 {
    animation: blink 1.4s infinite 0.4s;
}
