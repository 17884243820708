.class-p1{
    display: flex !important;

}


.class-p2{
    display: none !important;
}
@media (min-width:991px){
    .class-p1{
        display: none !important;
    }

    .class-p2{
        display: flex !important;
    }
}