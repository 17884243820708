/* Blog Listing Styles */
.blog-listing-container {
    padding: 20px;
  }
  
  .blog-listing-container  h2 {
    margin-bottom: 30px;
    font-family: Inter;
font-size: 36px;
font-weight: 700;
line-height: 40px;
letter-spacing: 0.0144em;
text-align: left;

    color: #FF8400;
  }
  
  /* Blog Cards Grid */
  .blog-list {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
    margin-bottom: 20px;
  }
  .blog-list .blog-link{
    text-decoration: none;
  }
  .card__blogs {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    height: 100%;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .card__blogs:hover {
    transform: scale(1.02);
  }
  
  .card-img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .blog-listing-container .card-body {
    padding:15px 0px 5px 0;
    font-family: Inter;
  }
  .blog-listing-container .card-body .poster-name{
    margin: 0;
  }
  .blog-listing-container .card-body .card-title{
    font-family: Inter;
  }
  .img-container .author-img{
    border-radius: 50%;
  }
  .blog-listing-container  .blog-title-container{
    width: fit-content;
  }
  .blog-listing-container  .blog-title-container .blog-title{
    margin-bottom: 4px;
  }
  .blog-listing-container .card-title{
    color: #333333;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  }
  .card-text {
    margin: 0;
    color: #333;
    text-decoration: none;
  }
  
  .card-body-bottom {
    padding: 10px 15px;
    border-top: 1px solid #eee;
  }
  
  .img-container {
    margin-right: 10px;
  }
  
  .poster-name {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
  }
  
  .time-to-read {
    margin: 0;
    color: #777;
    font-size: 0.9rem;
  }
  
  /* Pagination */
  .pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .pagination-btn {
    background-color: #FF8400;;
    color: white;
    border: none;
    font-weight: 500;
    padding: 10px 15px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .pagination-btn.prev-btn, .pagination-btn.next-btn{
    border-radius: 5px;
  }
  
  .pagination-btn:hover {
    background-color: #4C4C4C;
  }
  
  .pagination-btn.active {
    background-color: #4C4C4C;
  }
  
  /* Responsive Design */
  @media screen and (min-width: 1200px) {
    .card-body-bottom {
      padding: 20px;
    }
  
    .poster-name {
      font-size: 1.2rem;
    }
  
    .time-to-read {
      font-size: 1rem;
    }
  }
  