/* ContactUsForm.css */
.gs__contact-us-form.contact-us-form {
    max-width: 600px;
    margin: 0 auto;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .gs__contact-us-form.contact-us-form h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
.gs__contact-us-form  .form-group {
    margin-bottom: 20px;
  }
  
.gs__contact-us-form  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }
  
 .gs__contact-us-form .form-group input,
 .gs__contact-us-form .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    outline: none;
    transition: border 0.3s;
  }
  
  .gs__contact-us-form .form-group input:focus,
 .gs__contact-us-form .form-group textarea:focus {
    border-color: #34ad27;
  }
  
 .gs__contact-us-form .form-group input.error-input,
 .gs__contact-us-form .form-group textarea.error-input {
    border-color: red;
  }
  
 .gs__contact-us-form .error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
 .gs__contact-us-form .submit-btn {
    width: fit-content;
    background-color: #FF8400;
    color: white;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
.gs__contact-us-form  .submit-btn:hover {
    background-color: #2d9022;
  }
  