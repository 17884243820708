.language-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-btn {
    background-color: #f2f2f2;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid #ccc;
font-family: Inter;
font-size: 12px;
font-weight: 600;
line-height: 20px;
letter-spacing: 0.0144em;
text-align: left;

  }
  
  
  .dropdown-btn:hover {
    background-color: #e6e6e6;
  }
  
  .dropdown-content {
    display: block;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 5px;
    padding: 0;
    margin-top: 5px;
    border: 1px solid #ddd;
  }
  
  .dropdown-content button {
    color: #333;
    background-color: #fff;
    padding: 10px 20px;
    text-align: left;
    border: none;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 16px;
  }
  
  .dropdown-content button:hover {
    background-color: #f1f1f1;
  }
  
  .globe-icon {
    margin-right: 8px;
  }
  
  /* Optional media queries for responsive design */
  @media (max-width: 768px) {
    .dropdown-btn {
      font-size: 14px;
    }
  
    .dropdown-content button {
      font-size: 14px;
    }
  }
  