@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jaldi:wght@400&display=swap");

body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --font-jaldi: Jaldi;
  --font-hind: Hind;
  --heading-h3-semibold: "Work Sans";
  --text-xl-bold: "Plus Jakarta Sans";
  --font-roboto: Roboto;
  --font-inter: Inter;

  /* font sizes */
  --font-size-9xl: 1.75rem;
  --body-b4-medium-size: 1rem;
  --heading-h3-semibold-size: 1.5rem;
  --text-xl-bold-size: 1.25rem;
  --body-b5-regular-size: 0.875rem;
  --text-lg-semi-bold-size: 1.125rem;
  --font-size-xs: 0.75rem;
  --font-size-21xl: 2.5rem;
  --font-size-mini: 0.938rem;
  --font-size-45xl: 4rem;
  --font-size-3xs: 0.625rem;
  --font-size-5xs: 0.5rem;
  --font-size-11xl: 1.875rem;
  --font-size-smi: 0.813rem;

  /* Colors */
  --white: #fff;
  --color-darkorange-100: #ff8400;
  --color-darkorange-200: #f78000;
  --secondary-100: #e8e8ea;
  --color-gainsboro-100: #d9d9d9;
  --secondary-400: #97989f;
  --color-gray-100: #292929;
  --secondary-700: #242535;
  --color-gray-200: #202020;
  --secondary-800: #181a2a;
  --color-silver-100: #bababf;
  --color-dimgray: #5a5a64;
  --secondary-600: #3b3c4a;
  --color-black: #000;
  --color-sandybrown: #ffb769;
  --color-beige: #cee1c2;
  --color-limegreen: rgba(52, 173, 39, 0.43);
  --color-burlywood: #ffcb95;
  --theme-1-primary: #4b6bfb;

  /* Gaps */
  --gap-base: 1rem;
  --gap-xl: 1.25rem;
  --gap-xs: 0.75rem;
  --gap-11xl: 1.875rem;
  --gap-5xs: 0.5rem;
  --gap-9xs: 0.25rem;
  --gap-3xs: 0.625rem;
  --gap-5xl: 1.5rem;

  /* Paddings */
  --padding-base: 1rem;
  --padding-5xs: 0.5rem;
  --padding-13xl: 2rem;
  --padding-xs: 0.75rem;
  --padding-xl: 1.25rem;

  /* Border radiuses */
  --br-31xl: 50px;
  --br-xs: 12px;
  --br-9xl: 28px;
  --br-7xs: 6px;
  --br-5xs: 8px;
  --br-3xs: 10px;
  --br-11xs: 2px;
  --br-281xl: 300px;
  --br-151xl-7: 170.7px;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
li,
div,
input,
button,
select,
textarea {
  font-family: 'Inter', sans-serif;
}