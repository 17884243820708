.terms-and-conditions{
    margin: 2vw 2vw;
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-inline: 10px;
    border-radius: 4px;
   
}


.terms-and-conditions p a{
    text-decoration: underline;
    color: #212539;
}

.terms-and-conditions .head-description h2 {
    font-weight: 600;
   
    color: #212539;
    font-size: 30px;
  
}

 .terms-and-conditions .head-description.head-remove-list-style ul{
    list-style-type: none;
    font-weight: 800;
 }

 .terms-and-conditions .head-description p span{
    font-weight: 800;
    
    color: #212539;
 }
