.blog-editor-container .blog-title {
    width: 100%;
    padding: 10px;
    font-size: 1.8rem;
    font-weight: bold;
    border: 2px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .blog-editor-container .ql-toolbar {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
  }
 .blog-editor-container .ql-container {
    height: 400px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
    background-color: #fff;
  }
  
.blog-editor-container  .ql-editor {
    font-size: 1rem;
    line-height: 1.6;
  }
.blog-editor-container  .publish-button {
    background-color: #ff5722;
    color: #fff;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
.blog-editor-container  .publish-button:hover {
    background-color: #e64a19;
  }
.blog-editor-container{
  position: relative;
  padding-top: 20px;
  /* background-color: #fff; */
}

.blog-editor-container button.publish-btn{
  position: absolute;
  right: 10px;
  top: 36px;
  border-radius: 0px;
  background-color: #ff8400;
  border: none;
}

.blog-editor-container .editor-heading{
  color: #444;
  text-align: center;
}


  .blog-form-content .ql-container{
    max-width: 740px;
    margin: 10px auto;
    height: 800px;
  }
  /* .blog-form-content label, .blog-form{
padding: 10px;
  } */
  .blog-form {
    margin-bottom: 16px;
  }
  .blog-form input{
    border: 0;
    border-radius: 0px;
    margin-inline: 10px;
    border-bottom:1px solid #ff5722;
    background-color: inherit;
    width: -webkit-fill-available;
    
  }
  
  .blog-form label{
    margin-left: 10px;
    margin-bottom: 10px;
    color: #444;
  }

  .ql-toolbar.ql-snow{
    border: 0;
    padding-inline: 0;
    border-radius: 0;
    border-bottom: 1px solid #ccc;
    margin-inline: 10px;
  }