.contact-us-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 400px; /* Max width for larger screens */
    width: 100%; /* Full width for smaller screens */
    transition: box-shadow 0.3s;
  }
  
  .contact-us-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .contact-detail {
    margin: 8px 0;
  }
  
  @media (max-width: 600px) {
    .contact-us-card {
      padding: 12px; /* Smaller padding on mobile */
    }
  
    .contact-detail {
      font-size: 14px; /* Smaller font size on mobile */
    }
  }
  