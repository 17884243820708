.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  }
  
  .not-found-title {
    font-size: 6rem;
    font-weight: bold;
    color: #ff4a4a;
  }
  
  .not-found-message {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .not-found-home-link {
    font-size: 1.2rem;
    color: #34AD27A3;
    text-decoration: none;
    border: 1px solid #34AD27A3;
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  .not-found-home-link:hover {
    background-color: #34AD27A3;
    color: white;
  }
  