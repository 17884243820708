.refund-policy{
    margin: 2vw 2vw;
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-inline: 10px;
    border-radius: 4px;
   
}
.refund-policy p a{
    text-decoration: underline;
    color: #212539;
}
.refund-policy .head-description h2{
    font-weight: 600;
    font-family: El Messiri;
    color: #212539;
    font-size: 30px;
  
}

.refund-policy .head-description p{
    font-family: Inter;
    font-weight: 400;
    font-size: 1rem;
    color: #212539;
}