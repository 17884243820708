/* Blog Details Container */
.blog-details-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px;
    /* max-width: 900px; */
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  /* .main-blog-container{
    width: 75%;
  }
  .recent-blogs-container{
    width: 25%;
  } */
  /* Blog Image Styling */
  .blog-details-container .blog-image-container {
    width: 100%;
    text-align: center;
  }
  
  .blog-details-container .blog-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  /* Blog Content Styling */
  .blog-content-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Blog Title */
  .blog-details-container  .blog-title {
    font-size: 2.2rem;
    font-weight: bold;
    color: #333;
  }
  
  /* Blog Meta Information */
  .blog-details-container .blog-meta {
    font-size: 0.9rem;
    color: #777;
  }
  
  .blog-author, .blog-category, .blog-time-to-read, .blog-date {
    margin-right: 22px;
  }
  
  /* Blog Description */
  .blog-description {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .blog-title {
      font-size: 1.8rem;
    }
  
    .blog-description {
      font-size: 1rem;
    }
  
    .blog-meta {
      font-size: 0.8rem;
    }
  }
  
  .blog-description .blog_content_img{
    width: 100%;
    max-height: 400px;
    object-fit: fill;
    height: auto;
  }

  .recent-blogs-container h5{
    border-bottom: 2px solid rgba(255, 109, 59, .2);
    color: #ff6d3b;
    padding-bottom: .7rem;
    position: relative;
    font-weight: bold;
    position: relative;
    top: 12%;
  }
  .recent-blogs-container h5::after{

  
  content: "";
    position: absolute;
    bottom: -1px;
    background-color: #ff6d3b;
    width: 150px;
    height: 2px;
    left: 0;
  }

  .recent-blogs-container ,.main-blog-container{
    padding-inline: 6vw;
  }