body{
  background-color: #f5f5f5;
  width: 100%;
  padding: 0;
  scrollbar-width: none;
  overflow-x: hidden;
  margin: 0;
}

/* custom class for forms styles */
.br-20 {
  border-radius: 20px;
}

.header__strip {
  height: 40px;
  background-color: #FF8400;
}

.header__strip .quote h1 {
  font-family: Jaldi;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: #FFFFFF;
}



.header__strip .btn-container-header-strip button{
  background-color: #FFFFFF;
font-family: Inter;
font-size: 12px;
font-weight: 500;
line-height: 20px;
color: #333333;
letter-spacing: 0.0144em;
text-align: left;
padding: 2px 4px;
}
@media (max-width:768px) {
  .header__strip{
    height: fit-content;
    padding: 6px 8px;
  }
  .header__strip .quote{
    display: flex;
    align-items: center;
  }
  .header__strip .quote h1 {
    line-height: 12px;
    font-size: 12px;
    margin-bottom: 0;
  }

  .header__strip .btn-container-header-strip button{
    font-size: 9px;
    line-height: 12px;
  }
}
/* header start */

.gs_header .nav-link {
  color: #333333;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  line-height: 1.25rem;
}

.gs_header .nav-link.active {
  padding: 4px, 8px, 4px, 8px;
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 4px;
  background: #FF8400A3;
  color: #FFFFFF;
}
.gs_header .navbar-brand img{
  width: 40px;
}
.gs_header .donate-btn-top {
  border-radius: 4px;
 font-family: Inter;
  color: #ffffff;
  font-weight: 600;
  background-color: #FF8400;
  font-size: 12px;
  padding: 4px 8px;
  line-height: 20px;
  border: none;
}

.gs_header .donate-btn-top:hover {
  background-color: #ffffff;
  color: #FF8400;
}

.gs_header .donate-btn-top .donate-image {
  width: 33px;
  height: auto;
  object-fit: cover;
}
@media (max-width:767px){
  .gs_header .navbar-toggler-icon{
    width: 20px;
    height: 20px;
  }

 .gs_header button.navbar-toggler:focus{
    outline: none;
    box-shadow: none;
  }
}
@media(max-width:991px){
  .gs_header  .navbar-collapse.collapse.show{
    margin-bottom: 10px;
  }
}

/* header end */


/* header quote */
.header__quote {
  height: 54px;
  align-items: center;
  display: flex;
  background: linear-gradient(-45deg, rgba(52, 173, 39, 1) 20%, rgba(255, 132, 0, 1) 20%, rgba(255, 132, 0, 1) 80%, rgba(52, 173, 39, 1) 80%);
  justify-content: center;
}
.quotes-heading {
  overflow: hidden;
  width: 55vw;
}

.quotes-heading h1 {
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #FFFFFF;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;   /* Adjust width as per requirement */
  animation: marquee 10s linear infinite alternate;

}

.quotes-heading h1 {
  /* animation-name: slideIn;
  animation-duration: 1s;
  animation-delay: 1ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out; */
}

/* @keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
} */
@keyframes marquee {
  0% {
    transform: translateX(100%); /* Start from the right */
  }
  100% {
    transform: translateX(-100%); /* Move to the left */
  }
}


.gs__footer {
  background-color: #292929;
}

.gs__footer .row:first-child {
  padding: 45px 22px 0px 22px;
}

.gs__footer .row:last-child {
  padding: 22px;
  padding-top: 0px;
}

.gs__footer .about__description {
  color: #97989F;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  font-family: "Plus Jakarta Sans";
}

.gs__footer .about__title,
.gs__footer .heading-footer__links {
  color: #ffffff;
  line-height: 1.75rem;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
  font-size: 18px;
}

.gs__footer .gs__footer-links-container ul {
  padding-left: 0;
}

.gs__footer-links-container>div{
  gap: 40px;
}

.gs__footer .bottom-heading {
  font-family: "Plus Jakarta Sans";
  line-height: 28px;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}

.gs__footer .ul,
.gs__footer ul li {
  text-decoration: none;
  list-style-type: none;
}

.gs__footer .footer-navigation-list a {
  font-weight: 400;
  color: #BABABF;
  line-height: 27px;
  font-size: 18px;
  font-family: 'Plus Jakarta Sans';
}

.gs__footer .footer__details-container .details-title {
  font-family: Plus Jakarta Sans;
  font-size: 22.5px;
  font-weight: 700;
  line-height: 31.5px;
  text-align: left;
  
  color: #ffffff;
}
.gs__footer .contact__section .contact__email span:nth-of-type(2),
.gs__footer .contact__section .contact__phone span:nth-of-type(2){
  font-family: Plus Jakarta Sans;
font-size: 18px;
font-weight: 400;
line-height: 27px;
text-align: left;
color: #97989F;
}
.gs__footer .footer__details-container .detail-details {
  font-family: 'Plus Jakarta Sans';
  color: #BABABF;
  margin-bottom: 0px;
  font-family: Plus Jakarta Sans;
font-size: 18px;
font-weight: 700;
line-height: 27px;
text-align: left;

}
.gs__footer .footer__condition-container{
  gap: 30px;
}
.gs__footer .footer__condition-container a {
  text-decoration: none;
  color: #BABABF;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  font-family: Plus Jakarta Sans;

}



/* gs main banner */
.gs_main-banner {
  padding: 25px 0 45px 0;
}

.gs_main-banner .banner-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: 2.88%;
  color: #4C4C4C;
font-family: Inria Serif;
line-height: 32px;


}

.gs_main-banner .banner-subtitle {
  font-weight: 700;
  font-size: 48px;
  color: #FF8400;
font-family: Inria Serif;
line-height: 64px;
letter-spacing: 0.0384em;


}

.gs_main-banner .banner-description {
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  letter-spacing: 1.92%;
  color: #333333;
font-family: Inter;


}

.gs_main-banner .gs_main-banner-btn {
  text-decoration: none;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  background-color: #FF8400;
  padding: 8px 16px;
  border-radius: 4px;
  color: #ffffff;
  font-family: Inter;
}
.gs_main-banner .banner-btn-container{
  padding-top: 12px;
}

/* carousel */
.carousel {
  position: relative;
  width: 100%;
  height: 260px;
  margin: 0 auto;
  border-radius: 10px;
}

.carousel-images {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-image {
  width: 100%;
  height: 260px;
  display: none;
  object-fit: cover;
  border-radius: 10px;
}

.carousel-image.active {
  display: block;
}

.carousel-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.carousel-button {
  box-shadow: 4px 4px 4px 0px #00000029;
  background-color: #F5F5F5;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.carousel-button.prev{
    right: 20px;
    box-shadow: -4px 4px 4px 0px #00000029;

}
.carousel-button.next{
  left: 20px;
}
.carousel-dots {
  position: absolute;
  bottom: -26px;
  width: 100%;
  text-align: center;
}

.dot {
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: #B5B5B5;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #FF8400;
  width: 50px;
}

/* grass banner */
.gs__grass-wrapper {
  background-image: url(./assets/Banner/bgGreenGrass.png);
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
}

.gs__grass-wrapper .heading {
  font-weight: 700;
  font-size: 72px;
  line-height: 86px;
  text-align: center;
  /* box-shadow: 0px 4.48px 4.48px 0px #00000040; */
}

.gs__grass-wrapper .description {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  margin: 0 9vw;
  font-family: Inter;


}

.gs__activity-banner {
  background-image: url(./assets/Banner/baBlackCows.png);
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
}


/* activity container */
.gs_activity-container .container-heading {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
.gs_activity-container .gs__activity-card-container{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 20px;
}

@media (min-width:768px) and (max-width:991px) {
  .gs_activity-container .gs__activity-card-container{
    
    grid-template-columns: repeat(3,1fr);
  }
}
@media (max-width:768px) {
  .gs_activity-container .gs__activity-card-container{
    display: flex;
    width: 100%;
    min-width: 100%;
    flex-direction: row;
    scroll-behavior: smooth;
    overflow-x: scroll;
    scrollbar-width: none;
  }
  .gs_activity-container .gs__activity-card-container .card.card__activities{
    width: 100%;
    min-width: 274px;
  }
}


.gs_activity-container .gd_activity-heading {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #FF8400;
}

.gs_activity-container .btn-containre a {
  color: #4C4C4C;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
}

/* form styles */
.form-container_donation {
  background-image: url(./assets/formBackground.png);
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0 50px 0;
  border-radius: 20px;
  overflow: hidden;
}

.form-donate-btn {
  width: 100%;
  border-radius: 8px;
  border: 2px solid white;
  font-family: 1.25rem;
}


.gs_recognition-card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  margin: 0 !important;
  border: 1px solid #FF8400 !important;
  border-radius: 8px;
}


/*  */
.img-container img {
  width: 100%;
  object-fit: contain;
  right: 40px;
  z-index: 9;
  height: 100%;
  max-height: 260px;
}

.cow-img-container img {
  z-index: 2;
  z-index: 2;
  border-start-start-radius: 73%;
  border-end-start-radius: 50%;
  border-end-end-radius: 70%;
  border-end-start-radius: 75%;
  width: 312px;
  margin-right: 20px;
}

.background-img-container img {
  z-index: 1;
  right: 10px;
}

/* blog card css */
.card__blogs {
  padding: 16px;
  width: 100%;
  max-width: 370px;
  background-color: #ffffff;
  border: none;
}

.card__blogs .card-text {
  color: #333333;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.card__blogs .card-body-bottom {
  padding-top: 0;
  padding-bottom: 0;
}

.card__blogs .card-body .poster-name {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #808080;
  margin-left: 10px;
  margin-top: 1rem;
}

.card__blogs .card-body .card-title {
  font-size: 1.5rem;
  font-family: El Messiri;
font-weight: 700;
letter-spacing: 0.0144em;
  margin-bottom: 10px;
}


/* activites card css */
.card__activities {
  max-width: 274px;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #FFFFFF;
}

.card__activities .card-heading {
  color: #191919;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
}

.card__activities .card-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #808080;
}

.activity-read-more-btn {
  width: 100%;
  color: #FFFFFF;
  background-color: #FF8400;
  padding: 8px, 88.5px, 8px, 88.5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

/* mission card */
.card__mission {
  max-width: 370px;
  margin: 4px 0;
  padding: 1rem;
  border: none;
  border-radius: 1rem;
  background-color: #FFFFFF;
  box-shadow: 4px 4px 16px 0px #00000029;
}

.card__mission:hover {
  box-shadow: -4px -4px 16px 0px #00000029;
}

.card__mission:hover .card-heading {
  color: #FF8400;
  ;
}

.card__mission .card-heading {
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
  color: #191919;
}

.card__mission .card-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.card__mission .mission-donate-btn {
  width: 100%;
  color: #FFFFFF;
  background-color: #FF8400;
  padding: 8px, 88.5px, 8px, 88.5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.card__mission .value-container .value1,
.card__mission .value-container .value2 {
  font-weight: 500;
  font-size: 8px;
  line-height: 1rem;
  color: #4C4C4C;
}

/* missoin slider */
.card__mission .slider-container {
  position: relative;
  width: 100%;
}

.card__mission .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: linear-gradient(to right, #FF8400 0%, #FF8400 var(--slider-value, 0%), #ddd var(--slider-value, 0%), #ddd 100%);
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  border-radius: 5px;
}

.card__mission .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 34px;
  height: 24px;
  background: #FF8400;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
}

.card__mission .slider::-moz-range-thumb {
  width: 50px;
  height: 25px;
  background: #FF8400;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
}



.card__mission .slider-value {
  position: absolute;
  top: 3px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 500;
  display: flex;
  animation: left 0.1s ease-in-out;
  align-items: center;
  width: 34px;
  justify-content: center;
}
.card__mission .slider-value span{
font-family: Inter;
font-size: 8px;
font-weight: 500;
line-height: 16px;
letter-spacing: 0.016em;
text-align: left;

}
/* accordian  item*/
.faq_item {
  border: 1px solid #dee2e6 !important;
  border-radius: 8px;
}

.faq_item .accordion-button {
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
  color: #4C4C4C;
}

/* faq */
.faq_section .btn-container a {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #4C4C4C;
  text-decoration: none;
}



/* blogs container */
.gs__blogs-container {
  background-color: #f5f5f5;
}
.gs__blogs-container .gs__blog-card-container{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 10px;
}


@media (min-width:768px) and (max-width:991px) {
  .gs__blogs-container .gs__blog-card-container{
    
    grid-template-columns: repeat(2,1fr);
  }
}
@media (max-width:768px) {
  .gs__blogs-container .gs__blog-card-container{
    display: flex;
    width: 100%;
    min-width: 100%;
    flex-direction: row;
    scroll-behavior: smooth;
    overflow-x: scroll;
    scrollbar-width: none;
  }
  .gs__blogs-container .gs__blog-card-container .card.card__blogs{
    width: 100%;
    min-width: 310px;
  }
}


/* testimonial container */
.gs__testimonal-container {
  /* padding: 50px 0 40px 0; */
}
.gs__testimonal-container .gs__testimonial-card-container{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 20px;
}

@media (min-width:768px) and (max-width:991px) {
  .gs__testimonal-container  .gs__testimonial-card-container{
    
    grid-template-columns: repeat(3,1fr);
  }
}
@media (max-width:768px) {
  .gs__testimonal-container  .gs__testimonial-card-container{
    display: flex;
    width: 100%;
    min-width: 100%;
    flex-direction: row;
    scroll-behavior: smooth;
    overflow-x: scroll;
    scrollbar-width: none;
  }
  .gs__testimonal-container .gs__testimonial-card-container .card.card__testimonal{
    width: 100%;
    min-width:274px;
  }

}
/* testimonial card*/
.card__testimonal {
  max-width: 274px;
  background-color: #FFFFFF;
  border-radius: 16px;
}

.card__testimonal .card-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #191919;

}

.card__testimonal .card-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #808080;
}
.card__testimonal-image{
    width:100px;
    height:100px;
    border: 2px solid #FF8400;
}
.card__testimonal .star{
  font-size: 1.5rem;
  color:#D9D9D9;
  margin: 0px 2px;
}
.card__testimonal .star.filled{
    color: rgb(255, 132, 0);
}

/*  stars*/
.star {
  font-size: 1.5rem;
  color: #ddd;
  /* Default color for unfilled stars */
  margin: 0 2px;
}

.star.filled {
  color: #FF8400;
  /* Color for filled stars */
}

/* gs__video-banner video banner */
.gs__video-banner {
  background-color: #f5f5f5;
  border-radius: 8px;
}
.gs__video-banner .video-content-parent{
  border-radius: 8px;
  position: relative;
}
.gs__video-banner .video-content-parent::before{
  position: absolute;
  content: "";
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: #34AD2752;
  bottom: -50px;
  left: -50px;
  z-index: -1;
}
.gs__video-banner .video-container iframe{
border-radius: 8px;
margin: 10px;
}
.video-content{
  margin: 10px 0;
}
.gs__video-banner .heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.gs__video-banner .content-title {
  color: #333333;
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
}

.gs__video-banner .content-description {
  color: #808080;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
}

.gs__video-banner .btn-container a {
  text-decoration: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
  padding: 8px 16px;
  background-color: #FF8400;
  border-radius: 4px;
}

.gs__haritage-container {
  background-color: #f5f5f5;
}
.gs__haritage-container .gs__mission-cards-container{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 20px;
}

@media(min-width:768px) and (max-width:991px){
  .gs__haritage-container .gs__mission-cards-container{
    justify-items: center;
    grid-template-columns: repeat(2,1fr);
  }

}

@media(max-width:768px){
  .gs__haritage-container .gs__mission-cards-container{
    display: flex;
    width: 100%;
    min-width: 100%;
    flex-direction: row;
    scroll-behavior: smooth;
    overflow-x: scroll;
    scrollbar-width: none;
  }
  .gs__haritage-container .gs__mission-cards-container  .card.card__mission{
    width: 100%;
  min-width: 310px;
  }
}

.gs__haritage-container .title {
  font-weight: 700;
  font-size: 48px;
  color: #191919;
  line-height: 64px;
  font-family: Inria Serif;
}

.gs__haritage-container .title span {
  color: #FF8400;
}

.gs__haritage-container .subtitle-cotainer {
  font-family: Inria Serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.0288em;
  text-align: left;
  
  color: #808080;
}


/* login-modal */

.btn-login-modal {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #4C4C4C;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.btn-login-modal:hover {
  background-color: #ffffff;
  color: #4C4C4C;
}
.gs__login-model .eye-btn{
  top: calc(50% + 15px);
}
.gs__login-model.show{
  display: block !important;
}

.gs__newsletter {
  background-color: #5A5A64;
  padding: 30px;
  border-radius: 13.5px;
}

.gs__newsletter .title {
  font-family: Work Sans;
  font-size: 22.5px;
  font-weight: 600;
  line-height: 27px;
  color: #fff;
  text-align: center;
}

.gs__newsletter .description {
  font-family: Work Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #F5F5F5;
}

.gs__newsletter form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.gs__newsletter .input-container{
  position: relative;
  width: 100%;
}
.gs__newsletter .input-container img{
  position: absolute;
  top: 22%;
  right: 10px;
}
.gs__newsletter input{
  background-color: #292929;
  border: 1.13px solid #3B3C4A;
  color: #97989F;
  padding: 13px;
  width: 100%;
  display: block;
  border-radius: 6px;
}
.gs__newsletter input:focus-visible{
  outline: none;
}
.gs__newsletter .btn-subscribe {
  font-size: 18px;
  size: 18px;
  line-height: 22px;
  width: 100%;
  font-family: 'work Sans';
  color: #ffffff;
  padding: 13.5px 22.5px;
  background-color: #FF8400;
}


.btn-bg-primary {
  background-color: #FF8400 !important;
  border: none;
}





/* about us page */
/* who we are banner start */



.text-content h1 {
  color: #FF8400;
  font-size: 42px;
  line-height: 32px;
  margin-bottom: 10px;
  position: relative;
  font-family: El Messiri;
font-weight: 700;
}

/* .text-content h1::after {
  content: '';
  display: block;
  width: 50px;
  height: 4px;
  background-color: #6abf4b;
  margin-top: 10px;
} */

.text-content p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #333333;
  font-weight: 400;
  font-family: Inter;
letter-spacing: 0.0192em;
text-align: left;

}

.join-btn {
  background-color: #FF8400;
  border-color: #FF8400;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  padding: 8px 16px;
  border-radius: 4;
}

.image-content .cows-saved {
  background-color: #FF8400E5;
  border-radius: 10px;
  width: 100%;
  max-width: 153px;
  height: 100%;
  max-height: 115px;
  bottom: -42px;
  left: -64px;
}

.image-content .cows-saved span {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  color: #FFFFFF;
}

.image-content .cows-saved p {
  font-size: 14px;
  line-height: 32px;
  font-weight: 600;
  color: #FFFFFF;
}

@media (max-width: 768px) {
  .image-content .cows-saved {
    left: 50%;
    transform: translateX(-50%);
    bottom: -40px;
  }
}



/* our roles */
.roles-responsibilities .background-color-back{
  position: relative;
}

.roles-responsibilities .background-color-back::after{
  position: absolute;
  content: "";
  background-color:#FF840033;
  right: -20px;
  top: 0;
  bottom: 0;
  width: 65%;
  height: 100%;
  z-index: -1;
  border-top-right-radius: 20px;
  border-bottom-right-radius:20px;
}
.roles-responsibilities h1 {
  color: #ff7b25;
  margin-bottom: 20px;
  font-family: El Messiri;
font-size: 42px;
font-weight: 700;
line-height: 32px;
text-align: left;

  position: relative;
}

/* .roles-responsibilities h1::after {
  content: '';
  display: block;
  width: 50px;
  height: 4px;
  background-color: #6abf4b;
  margin-top: 10px;
} */

.roles-responsibilities p {
  margin-bottom: 20px;
  font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.0192em;
text-align: left;

}

.card-header .btn-link {
  text-decoration: none;
  color: #333;
  font-size: 18px;
  width: 100%;
  text-align: left;
}

.card-header .btn-link:hover {
  text-decoration: none;
  color: #ff7b25;
}

.card {
  border: none;
  margin-bottom: 10px;
}

.card-header {
  background-color: #f9f9f9;
}

.card-header[aria-expanded="true"] {
  background-color: #ff7b25;
  color: white;
}

.card-header[aria-expanded="true"] .btn-link {
  color: white;
}

.card-body {
  background-color: white;
}

.img-fluid {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .roles-responsibilities h1 {
    text-align: center;
  }

  .roles-responsibilities p {
    text-align: center;
  }
}

/* about us journey till now */

.journey-till-now h1 {
  color: #ff7b25;
  font-size: 36px;
  margin-bottom: 20px;
  position: relative;
}

/* .journey-till-now h1::after {
  content: '';
  display: block;
  width: 50px;
  height: 4px;
  background-color: #6abf4b;
  margin-top: 10px;
} */

.journey-till-now p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.img-fluid {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .journey-till-now h1 {
    text-align: center;
  }

  .journey-till-now p {
    text-align: center;
  }

  .journey-till-now .row {
    flex-direction: column-reverse;
  }
}

@media (max-width: 576px) {
  .journey-till-now h1 {
    font-size: 28px;
  }

  .journey-till-now p {
    font-size: 14px;
  }
}

/* core vision image */

.core-vision {
  /* background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.core-vision .core-vision-content {
  z-index: 2;
  display: flex;
  align-items: center;
}
.core-vision .img-container{
  width: 100%;
  position: relative;
  right: 0px;
}
.core-vision img {
  border-radius: 10px;
  position: relative;
  max-height: none;
}
.core-vision .core-vision-content img{
  position: relative;
}
.core-vision .core-vision-content img::after{
  position: absolute;
  content: "";
  top: -22px;
  left: -51;
  width: 153px;
  height: 104px;
  z-index: -1;
  background-color: #34AD27B5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.core-vision .corner-design {
  position: absolute;
  content: "";
  top: -22px;
  left: -50px;
  width: 153px;
  height: 104px;
  z-index: -1;
  background-color: #34AD27B5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.core-vision h1 {
  color: #FF6600;
  /* Adjust the color according to your design */
  margin-bottom: 12px;
  font-family: El Messiri;
font-size: 42px;
font-weight: 700;
line-height: 32px;
text-align: left;

}

.core-vision p {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  font-family: Inter;
font-weight: 400;
letter-spacing: 0.0192em;
text-align: left;

}

/* about us our mission banner */

.about-us-our-mission {
  /* background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.about-us-our-mission .about-us-our-mission-content {
  z-index: 2;
}

.about-us-our-mission img {
  width: 100%;
    border-radius: 10px;
    object-fit: contain;
}

.about-us-our-mission .corner-design {
  position: absolute;
  top: -22px;
  right: 0;
  width: 153px;
  height: 104px;
  z-index: -1;
  background-color: #34AD27B5;
  /* Adjust the color according to your design */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.about-us-our-mission h1 {
  color: #FF6600;
  /* Adjust the color according to your design */
  margin-bottom: 20px;
  font-family: El Messiri;
font-size: 36px;
font-weight: 700;
line-height: 40px;
letter-spacing: 0.0144em;
text-align: left;

}

.about-us-our-mission p {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.0144em;
text-align: left;
}
.about-us-our-mission :first-of-type{
  margin-top: 10px;
}

/* about us cards  */
.core-vision-cards {
  background-color: #f5f5f5;
  background-image: url(../src/assets/Banner/cards-bg-img.svg);
  padding: 20px;
  margin-inline: 10px;
  
  padding: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position:center;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.core-vision-cards .cards-container .card{
  max-width: 347px;
}
.card {
  flex: 1 1 calc(33.333% - 20px);
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-body {
  padding: 0px;
  padding-bottom: 10px;
}

.card-icon {
  font-size: 2em;
  margin-bottom: 20px;
  color: #ff6600;
}

.card-title {
  color: #ff6600;
  margin-bottom: 15px;
}

.card-text {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .card {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .card {
    flex: 1 1 100%;
  }
}

/* about us banner */


.banner {
  position: relative;
  width: 100%;
  height: 200px;
  /* Adjust height as needed */
  background-image: url('../src/assets/Banner/about-us-banner-stretch.png');
  /* Update the path to your image file */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.banner-overlay h1 {
  font-family: 'Jaldi';
  color: white;
  font-size: 1.5em;
  margin: 0;
}




.gs__quote-banner.quote-container {
  background-color: #e0f7e0;
}

.gs__quote-banner .card-body {
  background-color: unset;
  padding: 40px 80px;
}

.gs__quote-banner .quote-heading {
  font-family: El Messiri;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.0144em;
  text-align: left;
  margin-bottom: 0px;
}

.gs__quote-banner .quote-name {
  font-family: El Messiri;
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.0144em;
  text-align: end;
}

.tabbed-container {
  padding: 20px;
  border-radius: 8px;
}

.tabbed-container .tab-content {
  margin-top: 30px;
}

.nav-tabs .nav-link {
  cursor: pointer;
  border: none;
}

.tabbed-container .nav {
  border: none;
}
.tabbed-container .nav-item{
  position: relative;
}
.tabbed-container .nav-item::before{
  position: absolute;
  content: "";
  width: 100%;
  right: 0;
  z-index: 0;
  top: 25%;
  border: 0.5px dashed #FF8400A3;
}
.tabbed-container .nav-item:last-of-type:before,
.tabbed-container .nav-item:first-of-type:before{
  width: 50%;
  right: 0;
}
.tabbed-container .nav-item:last-of-type:before{
  left: 0;
  right: unset;
}
.nav-link .btn-container .icon-container {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FF840080;
  width: 63px;
  height: 63px;
  border-radius: 50%;
}
.nav-link .btn-container .icon-container img{
  position: relative;
  z-index: 1;
}
.nav-link .btn-container .title-container p {
  color: #FF840080;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.0144em;
  text-align: center;
  margin-bottom: 0px;
}

.nav-link.active .btn-container .icon-container {
  background: #FF8400;
}

.nav-link.active .btn-container .title-container p {
  color: #FF8400;
}

.tabbed-container .nav-item {
  max-width: 208px;

}
.tabbed-container .nav-tabs .nav-link.active{
  background-color: unset;
}
.tabbed-content .content-img-container {
  position: relative;
}

.tabbed-content .content-img-container::before {
  content: "";
  position: absolute;
  top: -16px;
  left: 10px;
  z-index: 0;
  width: 153px;
  height: 104px;
  background: #34AD27B5;
  border-radius: 10px;
}

.tabbed-content .content-container .line-container {
  width: 40%;
}

.tabbed-content .content-container .heading-container h3 {
  font-family: El Messiri;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.0144em;
  text-align: left;
  color: #FF8400;
}

.tabbed-content .content-container p {
  margin-top: 10px;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.0192em;
  text-align: left;
}

.tabbed-content .heading-container h3{
  line-height: 40px;
}

@media (max-width: 991.98px) {
  .nav-tabs {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    flex-wrap: nowrap;
    flex-direction: row;
    scrollbar-width: none;
  }

  .nav-item {
    flex: 0 0 auto;
  }

  .nav-tabs .nav-item .nav-link {
    white-space: normal;
    /* Allow the text to wrap */
  }

  .tabbed-container {
    padding: 0 15px;
    /* Adjust padding to ensure content is visible */
  }
}


/* banner cirlcel image */
.ga__banner-circle-image.container {
  margin-top: 50px;
  margin-bottom: 40px;
}

.ga__banner-circle-image .content-container .heading-container {
  width: fit-content;
}

.ga__banner-circle-image .content-container .heading-container h1 {
  font-family: El Messiri;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.0144em;
  text-align: left;
  width: fit-content;
  color: #FF8400;
}

.ga__banner-circle-image .content-container .line-container {
  margin-top: 20PX;
  width: 50%;
}

.ga__banner-circle-image .content-container .description-container p {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.0144em;
  text-align: left;
  color: #000000;
  margin-top: 20px;

}


.circle-image {
  position: relative;
}

.circle-image img {
  width: 100%;
  height: 100%;
  max-width: 350px;
  max-height: 400px;
  object-fit: cover;
}

.circle-text {
  position: absolute;
  bottom: 10px;
  left: 0;
  transform: translate(-50%, -50%);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}


/* about us faq css */
.container-wrapper-gs__about-us-faq {
  background-color: #FF84000D;
  border: 2px solid #FF84001A;
}

.gs__about-us-faq .heading-1 {
  color: #FF8400;
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
}

.gs__about-us-faq .heading-2,
.gs__about-us-faq .heading-3 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 0px;
  line-height: 41.6px;
  color: #000000;
}
.gs__about-us-faq .heading-3{
  margin-bottom: 24px;
}
.gs__about-us-faq .secondary-heading-container .secondary-heading-one {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 31.2px;
  text-align: left;
  color: #000000;
}

.gs__about-us-faq .secondary-heading-container .secondary-heading-two {
  color: #898989;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
  margin-bottom: 20px;
}

.gs__about-us-faq .accordion-item {
  background-color: transparent;
  border: unset;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.gs__about-us-faq .accordion-item:first-of-type {
  border-radius: 5px;
}

.gs__about-us-faq .accordion-item:first-of-type>.accordion-header .accordion-button {
  border-radius: 5px;
}



.gs__about-us-faq .accordion-button {
  background-color: #FFFFFF;
  color: #000000;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;

}

.gs__about-us-faq .accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: url(./assets/icons/arrow-down-sign-to-navigate.png);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

.gs__about-us-faq .accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: unset;
}


.gs__about-us-faq .accordion-button:not(.collapsed) {
  box-shadow: unset;
}

.gs__about-us-faq .accordion-collapse {
  background-color: transparent;
}

.gs__about-us-faq .accordion-body {
  padding:12px 20px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
}


.gs__about-us-faq .faq-image-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "first-image-container second-image-container"
    "first-image-container third-image-container";
  gap: 10px;
}

.gs__about-us-faq .faq-image-container .faq-image {
  width: 100%;
}


.gs__about-us-faq .first-image-container {
  grid-area: first-image-container;
  height: 100%;
  /* Ensures it takes the full height of the container */
}

.gs__about-us-faq .second-image-container {
  grid-area: second-image-container;
}

.gs__about-us-faq .third-image-container {
  grid-area: third-image-container;
}

.gs__payment-form form {
  padding: 24px;
  border: 1px solid #FF840080;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.gs__payment-form legend {
  margin-top: -35px;
  background-color: #FFD5A8;
  border-radius: 5px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 40px;
  letter-spacing: 0.0144em;
  text-align: left;
  max-width: 320px;
  padding-left: 12px !important;
}

.gs__payment-form fieldset {
  border: 1px solid #E4E4E4;
  background-color: #FF840012;
}

.gs__payment-form input,
.gs__payment-form select {
  font-family:Inter;
  color:#000000 !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.0144em;
  text-align: left;
  border: none;
  border-bottom: 1px solid #E4E4E4;
  background-color: transparent;
  border-radius: unset;
}
.gs__payment-form .form-check-input[type=checkbox]{
  border: 2px solid #FF8400;
  border-radius: 0px;
}
.gs__payment-form input:focus,
.gs__payment-form input:focus-visible,
.gs__payment-form select:focus,
.gs__payment-form select:focus-visible {
  outline: unset;
  box-shadow: unset;
  background-color: unset;
}
.gs__payment-form .nav-tabs{
  border: 1px solid #FF840080;
  border-radius: 8px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}


.gs__payment-form button {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.0144em;
  text-align: left;
  color: #FFFFFF;
  background-color: #FF8400;
  padding: 20px 70px;
  border-radius: 4px;
  border: unset;
}
.gs__payment-form button:hover{
  color: #9B9B9B;
  background-color: #ffffff;
}
.gs__payment-form .nav-item .nav-link {
  color: #000000;
  font-family: El Messiri;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.0144em;
  text-align: left;
}

.gs__payment-form .nav-item:has(.nav-link.active) .nav-link {

  color: #ffffff;

  background-color: #FF8400;
}
.gs__payment-form  .monthly-time-payment.active{
  
  border-top-left-radius: 0px;
  border-bottom-left-radius:0px;
  }
  .gs__payment-form  .one-time-payment.active{
    border-top-right-radius: 0px;
    border-bottom-right-radius:0px;
  }

.payment-status__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 132px);
  width: 100vw;
}

.payment-status__wrapper .payment-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 2px solid #FF8400;
  padding: 10px;
  border-radius: 4px;
}


.receipt-container {
  width: 600px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 4px;
  background-color: #fffacd;
  border-radius: 4px;
  font-family: Arial, sans-serif;
}

.receipt-header {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.receipt-title {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.receipt-body {
  margin-bottom: 20px;
  line-height: 1.5;
}

.receipt-body span {
  font-weight: bold;
}

.receipt-footer {
  text-align: right;
  margin-bottom: 20px;
}

.receipt-actions {
  text-align: center;
}

.receipt-actions button {
  margin: 5px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.receipt-actions button:hover {
  background-color: #45a049;
}

.gs__payment-status .download-recipt-btn {
  margin-left: auto;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

}


.faq-section .faq_item{
  border: none !important;
  border-radius: 8px;
}

.faq-section .accordion-button{
  border-radius: 8px;
font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.0192em;
text-align: left;
color: #4C4C4C;
}

.faq-section .accordion-button:not(.collapsed){
  background-color: unset;
  box-shadow: none;
}
.faq-section .accordion-button:focus{
  box-shadow: unset;
}
.faq-section .accordion-body{
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0144em;
  text-align: left;
  color: #191919;
}
.faq-section .accordion-button::after{
   width: 16px;
  height: 16px;
 background-size: unset;
}

section .btn-container a{
  text-decoration: none;
  color: #4C4C4C;
font-family: Inter;
font-size: 12px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0.0144em;
text-align: left;

}
section .btn-container a::after {
  margin-left: 2px;
content: ">";
margin-right: 10px;
}

.card__activities .card-body{
  padding-inline: 0;
  padding-bottom: 0px;
}

.activity-card .view-more-btn{
  border:none;
   background:none; color:#ff8400;cursor:pointer;
}
.activity-card .view-more-btn:hover{
  color: #9B9B9B;
}
.activity-description p{
  font-family: Inter;
font-size: 15px;
font-weight: 400;
line-height: 19.5px;
text-align: left;
color: #9B9B9B;
margin-bottom: 0;
}
.roles-responsibilities .accordian-item{
  margin-bottom: 20px;
}
.roles-responsibilities .accordion-header .accordion-button{
  background-color: #FF8400;
  color: #ffffff;
}
.roles-responsibilities .accordion-collapse.collapse.show{
  border: 1px solid #ff8400;
  border-top: 0;
}
.roles-responsibilities .accordion-button:focus{
  box-shadow: none;
}
.roles-responsibilities  .accordion-content{
  padding: 12px 20px;
  font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.0192em;
text-align: left;
color: #4C4C4C;

}
.roles-responsibilities .accordion-header .accordion-button{
  font-family: El Messiri;
font-size: 22px;
font-weight: 700;
line-height: 24px;
letter-spacing: 0.0192em;
text-align: left;
border: 1px solid #FF8400;
border-bottom: unset;
}
.roles-responsibilities .accordion-header .accordion-button.collapsed{
background-color: #EDEAEA;
font-family: El Messiri;
font-size: 22px;
font-weight: 700;
line-height: 24px;
letter-spacing: 0.0192em;
text-align: left;
color: #191919;
border: unset;
}

.roles-responsibilities .accordion-button.collapsed::after{
  background-image: url(./assets/icons/plus-icon.png);
  width: 13px;
  background-size: unset;
}

.roles-responsibilities .accordion-button::after{
background-image: url(./assets//icons/minus-icon.png);
width: 13px;
background-position: center;
  background-size: unset;
}



.gs__payment-form .input-field-label span{  
  color: red; vertical-align: middle; font-size: 18px;
}
.input-field-container{
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
}
.input-field-label{
  position: absolute;
    top: 12px;
    left: 15px;
    display: block;
    color: #000000;
    user-select: none;
    cursor: text;
    font-family: Inter;
font-size: 20px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0.0144em;
text-align: left;

}
.gs__payment-form input.input-field:focus + .input-field-label,
.gs__payment-form input.input-field:disabled + .input-field-label,
.gs__payment-form input.input-field:not(:placeholder-shown) + .input-field-label {
    display: none;
  }

  .gs__payment-form input.input-field:not(:focus):not(:disabled):placeholder-shown + .input-field-label {
    display: inline-block;
}

.gs__payment-form input.input-field::placeholder,
.gs__payment-form input.input-field:focus::placeholder,
.gs__payment-form input.input-field:not(:placeholder-shown)::placeholder,
.gs__payment-form input.input-field:disabled::placeholder {
    color: transparent; /* Hide placeholder */
    display: none;
}

.input-field-checkbox{
  border: 0;
  border: 2px solid #FF8400;
}
.input-field-checkbox:checked[type=checkbox]{
  background-color: #ff8400;
}


.gs__about-us-faq .accordion-body{
  color: #4C4C4C;
}

.who-we-are .image-content{
  position: relative;
}

.who-we-are .image-content::after{
  content: "";
  position: absolute;
  display: block;
  background-color: #34AD27B5;
  width: 153px;
  height: 104px;
  border-radius: 10px;
  right: 0px;
  top: -14px;
  z-index: -1;
}

.gs__blogs-container .gs__blog-card-container .card__blogs .card-body{
  padding-top: 10px;
}

.gs__blogs-container .gs__blog-card-container a{
  text-decoration: none;
 
}
.gs__blogs-container .gs__blog-card-container .card__blogs .blog-card-img{
  width: 100%;
  height: auto;
  object-fit: fill;
  height: 100%;
  max-height: 192px;

}