.user-aggrement{
    margin: 2vw 2vw;
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-inline: 10px;
    border-radius: 4px;
   
}

.user-aggrement  h2,.user-aggrement  h1{
    font-weight: 600;
    font-family: El Messiri;
    color: #212539;
    font-size: 30px;
  
}

.user-aggrement p{
    font-family: Inter;
    font-weight: 600;
    font-size: 1rem;
    color: #212539;
}
.user-aggrement ol{
    font-weight: 500;
}
.user-aggrement ol li{
    list-style: none;
    padding-left: 10px;
    font-weight: 300;
}