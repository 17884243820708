.react-multiple-carousel__arrow--left{
    left: 0;
    background-color: #F5F5F5;
    box-shadow: -4px 4px 4px 0px #00000029;

}

.react-multiple-carousel__arrow--right{
    right: 0px;
    background-color: #F5F5F5;
    box-shadow: 4px 4px 4px 0px #00000029;

}
.react-multiple-carousel__arrow::before{
    color:#808080;
}
.react-multiple-carousel__arrow:hover{
    background: #F5F5F5;
}

.carousel-container  .carousel-item-padding-40-px.react-multi-carousel-item--active:first-of-type{
    padding-left: 10px;
}
.carousel-container  .carousel-item-padding-40-px.react-multi-carousel-item--active:last-of-type{
    padding-right: 10px;
}

.carousel-item-padding-40-px{
    padding: 10px;
  }